import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Tooltip,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ResumeTemplates from "./dashboard/ResumeTemplates";
import CareerCheatSheets from "./dashboard/CheatSheets";
import MidCareerSwitchingDashboard from "./dashboard/MidCareerSwitching";
import LandDreamJobDashboard from "./dashboard/LandYourDreamJob";
import SpecialCoaching from "./dashboard/SpecialCoaching";
import Report from "./Report";
import { useParams } from "react-router-dom";
import SummaryPage from "./SummaryPage";
import CallMadeIcon from "@mui/icons-material/CallMade";

const CareerDashboard = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [hasUpgraded, setHasUpgraded] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const checkUpgradeStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade-status`
        );
        const data = await response.json();
        setHasUpgraded(data.hasUpgraded);
        setUsername(data.name || data.Name || "Guest"); // Fallback to 'Guest' if name is not available
      } catch (error) {
        console.error("Error checking upgrade status:", error);
      }
    };

    checkUpgradeStatus();
  }, [userId]);

  const cardData = [
    {
      title: "My Career Analysis",
      description:
        "Your personalized career report to help you understand your career goals and aspirations",
      image: "/images/Dashboard/Career Analysis Graphic.png",
      path: `/report/${userId}`,
      buttonText: "View Report",
      component: Report,
      requiresUpgrade: false,
      cardColor: "#F2F6FF",
    },
    {
      title: "My Career Scorecard",
      description: "Get a one page summary of your career report",
      image: "/images/Dashboard/Designer.png",
      path: `/report/${userId}?showSummary=true`,
      buttonText: "View Career Scorecard",
      component: SummaryPage,
      requiresUpgrade: true,
      cardColor: "#E1F5F6",
    },

    {
      title: "Your Career Strategy Toolkit",
      description:
        "Here are some useful short-cuts and tools to help you develop your Career Strategy and to make better-informed decisions",
      image: "/images/Dashboard/Toolkit Image.png",
      path: `/cheat-sheets/${userId}`,
      buttonText: "View Cheat Sheets",
      component: CareerCheatSheets,
      requiresUpgrade: true,
      cardColor: "#F9F3D8",
    },
    {
      title: "Making Your Mid-Career Switch (Online Course)",
      description:
        "Mid-Career Switches can be challenging, complicated and risky. Learn how to transition effectively using our proprietary 5-step Framework",
      image: "/images/Dashboard/MidCareer Switch Image.png",
      path: `/mid-career-switching/${userId}`,
      buttonText: "Learn More",
      component: MidCareerSwitchingDashboard,
      requiresUpgrade: true,
      cardColor: "#FFF4F1",
    },
    {
      title: "Landing Your Dream Job (Online Course)",
      description:
        "In a challenging job market where great opportunities are scarce and competition is tough, learn how you can improve your chances of landing your dream job here",
      image: "/images/Dashboard/Land Your Dream Job.png",
      path: `/land-your-dream-job/${userId}`,
      buttonText: "Get Started",
      component: LandDreamJobDashboard,
      requiresUpgrade: true,
      cardColor: "#FBFBE8",
    },
    {
      title: "Personalised Coaching",
      description: "Get personalized career coaching at exclusive member rates",
      image: "/images/Dashboard/Personalised Coaching.png",
      path: `/special-coaching/${userId}`,
      buttonText: "Learn More",
      component: SpecialCoaching,
      requiresUpgrade: true,
      cardColor: "#F9E8FB",
    },
    {
      title: "Resume Samples",
      description:
        "Download Resume samples with content that is tailored to your industry or function",
      image: "/images/Dashboard/Resume Templates Image.png",
      path: `/resume-templates/${userId}`,
      buttonText: "View Templates",
      component: ResumeTemplates,
      requiresUpgrade: true,
      cardColor: "#F7FFE4",
    },
  ];

  const DashboardCard = ({
    title,
    description,
    image,
    path,
    buttonText,
    requiresUpgrade,
    cardColor,
  }) => (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 3,
        opacity: !hasUpgraded && requiresUpgrade ? 0.7 : 1,
        p: 3,
        width: "100%",
        height: "auto",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: 200,
          backgroundColor: cardColor,
          borderRadius: 5,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "5%",
            width: "auto",
            height: "100%",
            paddingTop: "3%",
            paddingBottom: "3%",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",

            "& img": {
              width: "100%",
              height: "90%",
              objectFit: "contain",
            },
          }}
        >
          <img src={image} alt={title} />
        </Box>
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {title === " " ? (
          <Typography variant="body2" color="primary">
            Coming Soon
          </Typography>
        ) : (
          <>
            <Tooltip
              title={
                !hasUpgraded && requiresUpgrade
                  ? "Please upgrade to access this feature"
                  : ""
              }
            >
              <span>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(path)}
                  disabled={!hasUpgraded && requiresUpgrade}
                  endIcon={<CallMadeIcon />}
                  sx={{ backgroundColor: "#000000" }}
                >
                  {buttonText}
                </Button>
              </span>
            </Tooltip>
            {!hasUpgraded && requiresUpgrade && (
              <Typography variant="body2" color="error">
                Upgrade to View
              </Typography>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppBar
        position="static"
        className="menu-bar"
        elevation={0}
        sx={{ borderRadius: 20, marginBottom: 1 }}
      >
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="../images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          {/* <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate(`/dashboard/${userId}`)}
            sx={{
              color: "#000000",
              border: "1px solid #000000",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            View Dashboard
          </Button> */}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          My Career Dashboard
        </Typography>
        <Typography variant="body1" component="h1" gutterBottom>
          Hello {username}!
        </Typography>
        <Typography variant="body1" component="h1" gutterBottom sx={{ mb: 6 }}>
          Welcome to your one-stop Career Resource Centre. Please explore the
          links to find out how you can further enhance your Career Success.
        </Typography>

        <Box
          sx={{
            backgroundColor: "#8DDAF0",
            margin: "0 -24px", // Negative margin to counter parent padding
            padding: "0 24px", // Add padding back to maintain content alignment
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              padding: 4,
              alignItems: "stretch", // Ensures equal height rows
            }}
          >
            {cardData.map((card, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{ display: "flex" }} // Makes Grid item flex container
              >
                <DashboardCard {...card} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CareerDashboard;
