import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Grid,
  CardMedia,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import VideoPlayer from "./VideoPlayer";
import { useNavigate, useParams } from "react-router-dom";
const MidCareerSwitching = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();

  const videos = [
    {
      title: "Introduction to Mid-Career Switching",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203897/CareerSwitching_01_-_Introduction_ngov1r.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203897/CareerSwitching_01_-_Introduction_ngov1r.jpg",
    },
    {
      title: "What are the 5 Steps? Knowing Your Career Quadrants ",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203894/CareerSwitching_02_-_5_Steps_Quadrants_lnmi03.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203894/CareerSwitching_02_-_5_Steps_Quadrants_lnmi03.jpg",
    },
    {
      title: "What are your 4 Career Pivots?",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203895/CareerSwitching_03_-_Career_Pivots_ovusij.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203895/CareerSwitching_03_-_Career_Pivots_ovusij.jpg",
    },
    {
      title:
        "Identifying Your Career Adjacencies & Researching Your Target Markets",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203898/CareerSwitching_04_-_Career_Adjacencies_and_Research_z3vrnv.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203898/CareerSwitching_04_-_Career_Adjacencies_and_Research_z3vrnv.jpg",
    },
    {
      title: "Uncovering Opportunities via Networking",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_05_-_Networking_cvu8yb.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203887/CareerSwitching_05_-_Networking_cvu8yb.jpg",
    },
    {
      title: "Putting It All Together",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_06_-_Putting_it_together_xqzwgv.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724203887/CareerSwitching_06_-_Putting_it_together_xqzwgv.jpg",
    },
    {
      title: "Bonus Materials & Conclusion",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203889/CareerSwitching_07_-_Bonus_rdgyeo.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_4.0/v1724203889/CareerSwitching_07_-_Bonus_rdgyeo.jpg",
    },
  ];

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setOpenPlayer(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppBar
        position="static"
        className="menu-bar"
        elevation={0}
        sx={{ borderRadius: 20, marginBottom: 1 }}
      >
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="../images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate(`/dashboard/${userId}`)}
            sx={{
              color: "#000000",
              border: "1px solid #000000",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            View Dashboard
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box display="flex" alignItems="center" mb={2}>
              {/* <SwitchAccountIcon sx={{ fontSize: 40, mr: 2 }} /> */}
              <Typography gutterBottom variant="h5" component="div">
                Mid-Career Switching
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" paragraph>
              We understand that many Executives at your stage are considering
              Mid-Career Switches. However, such moves can be risky.
              <br />
              <br />
              We have created an online course for you to ensure a smoother,
              more Strategic move into your new role.
            </Typography>
            <Box
              sx={{
                backgroundColor: "#8DDAF0",
                margin: "0 -24px", // Negative margin to counter parent padding
                padding: "0 24px", // Add padding back to maintain content alignment
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  backgroundColor: "#8DDAF0",
                  p: 2,
                  borderRadius: 2,
                }}
              >
                {videos.map((video, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index} mb={4}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          pl: 3,
                          pr: 3,
                          pt: 3,
                          borderRadius: 5,
                        }}
                      >
                        <Box sx={{ position: "relative" }}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={video.thumbnail}
                            alt={video.title}
                            sx={{ borderRadius: 5 }}
                          />
                          <IconButton
                            onClick={() => handleVideoClick(video)}
                            sx={{
                              position: "absolute",
                              bottom: 8,
                              left: 8,
                              backgroundColor: "#415ED4)",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                              },
                              color: "white",
                            }}
                          >
                            <PlayCircleFilledRoundedIcon
                              sx={{
                                position: "absolute",
                                bottom: 8,
                                left: 8,
                                color: "#415ED4",
                                fontSize: 60,
                                backgroundColor: "#ffffff",
                                borderRadius: "50%",
                                "&:hover": {
                                  transform: "scale(1.1)",
                                  transition: "transform 0.2s",
                                },
                              }}
                            />
                          </IconButton>
                        </Box>
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "60px",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", alignItems: "left", gap: 1 }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "black",
                                color: "white",
                                minWidth: "24px",
                                height: "24px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              {index + 1}
                            </Box>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {video.title}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <VideoPlayer
          open={openPlayer}
          onClose={() => setOpenPlayer(false)}
          videoUrl={selectedVideo?.url}
          title={selectedVideo?.title}
        />
      </Box>
    </>
  );
};

export default MidCareerSwitching;
