// QuestionList.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  LinearProgress,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import UserInfoForm from "./UserInfoForm"; // Import the UserInfoForm
import "./Styles.css";
import "./UserInfoForm.css"; // Import the new CSS file
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const QuestionList = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState(location.state?.questions || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({ name: "", email: "" });
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpTimer, setOtpTimer] = useState(null);
  const navigate = useNavigate();
  const questionRefs = useRef([]);

  // const lightColors = [
  //   "#FFFFFF", // Cream
  //   "#000000", // Black
  // ];

  useEffect(() => {
    if (questions.length === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/questions`)
        .then((response) => {
          const fetchedQuestions = response.data.map((question) => ({
            ...question,
            // backgroundColor:
            //   lightColors[Math.floor(Math.random() * lightColors.length)],
          }));
          setQuestions(fetchedQuestions);
        })
        .catch((error) =>
          console.error("There was an error fetching the questions!", error)
        );
    }
  }, []);

  useEffect(() => {
    if (questionRefs.current[currentPage * 5]) {
      questionRefs.current[currentPage * 5].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentPage]);

  const handleAnswerChange = (questionId, answerId) => {
    setAnswers({ ...answers, [questionId]: answerId });
    if (questionRefs.current[questionId]) {
      questionRefs.current[questionId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const validateAnswers = () => {
    const startIndex = currentPage * 5;
    const endIndex = startIndex + 5;
    for (let i = startIndex; i < endIndex; i++) {
      const question = questions[i];
      if (!answers[question.id]) {
        return `Please answer all questions on this page.`;
      }
    }
    return "";
  };

  const handleNext = () => {
    const error = validateAnswers();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage("");
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSubmit = () => {
    const error = validateAnswers();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage("");
      setShowUserInfoForm(true);
    }
  };

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-otp`,
        { email: userInfo.email }
      );
      if (response.data.success) {
        setOtpSent(true);
        setErrorMessage("");
        setOtpTimer(
          setTimeout(() => {
            setErrorMessage("OTP expired. Please request a new one.");
            setOtpSent(false);
            setOtp("");
          }, 300000)
        ); // 5 minutes
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp`,
        { email: userInfo.email, otp }
      );
      if (response.data.success) {
        setOtpVerified(true);
        clearTimeout(otpTimer);
        const savedUser = await saveUserInfoAndAnswers();
        console.log("savedUser", savedUser);
        // Send report email
        const dashboardUrl = `${window.location.origin}/dashboard/${savedUser.id}`;
        await axios.post(`${process.env.REACT_APP_API_URL}/send-report-email`, {
          userId: savedUser.id,
          dashboardUrl: dashboardUrl,
        });
        return true; // Return true on success
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Error verifying OTP. Please try again.");
    }
  };

  const saveUserInfoAndAnswers = async () => {
    setLoading(true);
    try {
      const userResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        userInfo
      );
      const userId = userResponse.data.id;
      const userAnswers = Object.entries(answers).map(
        ([questionId, answerId]) => ({
          userId: userId,
          questionId: parseInt(questionId),
          answerId: parseInt(answerId),
        })
      );

      await axios.post(
        `${process.env.REACT_APP_API_URL}/useranswers`,
        userAnswers
      );
      // navigate(`/report/${userId}`);
      navigate(`/dashboard/${userId}`);
      // Return the saved user data
      return { id: userId, ...userInfo };
    } catch (error) {
      console.error(
        "There was an error submitting the user info and answers!",
        error
      );
    }
  };

  const calculateProgress = () => {
    const totalQuestions = questions.length;
    const answeredQuestions = Object.keys(answers).length;
    return Math.round((answeredQuestions / totalQuestions) * 100);
  };

  const renderQuestions = () => {
    const startIndex = currentPage * 5;
    const endIndex = startIndex + 5;

    return questions.slice(startIndex, endIndex).map((question, index) => {
      return (
        <div
          key={question.id}
          ref={(el) => (questionRefs.current[startIndex + index] = el)}
          className={`question-container ${
            startIndex + index === currentPage * 5 ? "highlight" : ""
          }`}
          // style={{ backgroundColor: question.backgroundColor }} // Apply the assigned background color
        >
          <Grid container direction="column" className="question-answers-row">
            <Grid item>
              <Typography variant="h6">
                <span
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    marginRight: "6px",
                  }}
                >
                  {startIndex + index + 1}
                </span>{" "}
                {question.text}
              </Typography>
            </Grid>
            <Grid item>
              <RadioGroup
                className="radio-group"
                aria-label={`question-${question.id}`}
                name={`question-${question.id}`}
                value={answers[question.id] || ""}
                onChange={(e) =>
                  handleAnswerChange(question.id, e.target.value)
                }
              >
                {question.answers.map((answer) => (
                  <FormControlLabel
                    key={answer.id}
                    value={answer.id}
                    control={
                      <Radio
                        sx={{
                          color: "#bdbdbd",
                          "& .MuiSvgIcon-root": {
                            fontSize: 30,
                          },
                          "&.Mui-checked": {
                            color: "#000000",
                          },
                        }}
                      />
                    }
                    label={answer.text}
                    className="answer-choice"
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  if (loading) {
    return <Typography>Generating Report...</Typography>;
  }

  return (
    <Box className="questions-page-container">
      <Box
        sx={{
          marginLeft: "10%",
          marginRight: "10%",

          marginBottom: "10px",
        }}
      >
        <AppBar
          position="static"
          className="menu-bar"
          elevation={0}
          sx={{ borderRadius: 20, marginBottom: 2 }}
        >
          <Toolbar variant="regular">
            <IconButton edge="start" color="inherit" aria-label="menu">
              <img
                src="./images/Logo/cai logo.jpg"
                alt="Company Logo"
                className="logo"
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Stepper activeStep={currentPage} alternativeLabel>
          {Array.from({ length: Math.ceil(questions.length / 5) }, (_, i) => (
            <Step key={i}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {showUserInfoForm ? (
        <UserInfoForm
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleSendOtp={handleSendOtp}
          handleVerifyOtp={handleVerifyOtp}
          otpSent={otpSent}
          otpVerified={otpVerified}
          otp={otp}
          setOtp={setOtp}
          errorMessage={errorMessage}
        />
      ) : (
        <>
          {renderQuestions()}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <Box className="navigation-buttons">
            {currentPage !== 0 && (
              <Button
                variant="contained"
                className="button-color"
                onClick={() => setCurrentPage(currentPage - 1)}
                startIcon={<KeyboardDoubleArrowLeftIcon />}
              >
                Previous
              </Button>
            )}
            {currentPage < Math.ceil(questions.length / 5) - 1 ? (
              <Button
                variant="contained"
                className="button-color"
                onClick={handleNext}
                endIcon={<KeyboardDoubleArrowRightIcon />}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-color"
                onClick={handleSubmit}
                endIcon={<DoneAllIcon />}
              >
                Submit
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuestionList;
