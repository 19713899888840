import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
  Fab,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { FastAverageColor } from "fast-average-color";
import "./ReportStyles.css";
import StarRating from "./StarRating";
import { preloadNextImage, getCardData, isVideo } from "./ReportData";
import DOMPurify from "dompurify";
import UpgradeCard from "./UpgradeCard";
import ChatButton from "./ChatButton";
import SummaryPage from "./SummaryPage";
import ReactConfetti from "react-confetti";

const Report = () => {
  const { userId } = useParams();
  const navigate = useNavigate(); // Use useNavigate
  const location = useLocation();
  const [report, setReport] = useState(null);
  const [currentCard, setCurrentCard] = useState(0);
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [hasPaid, setHasPaid] = useState(false); // Track payment status
  const [hasUpgraded, setHasUpgraded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [summary, setSummary] = useState(null);
  const [redirectToCard, setRedirectToCard] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [verifyingPayment, setVerifyingPayment] = useState(false);

  // After setting the report state

  const fetchUpgradeStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade-status`
      );
      console.log("Upgrade status response:", response.data);
      setHasUpgraded(response.data.hasUpgraded);
    } catch (error) {
      console.error("Error fetching upgrade status:", error);
    }
  }, [userId]);

  const fetchReportAndUpgradeStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const [reportResponse, upgradeResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URL}/useranswers/report/${userId}`
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade-status`
        ),
      ]);

      console.log("Report data:", reportResponse.data);
      console.log("Upgrade status:", upgradeResponse.data);

      setReport(reportResponse.data);
      setHasUpgraded(upgradeResponse.data.hasUpgraded);
    } catch (error) {
      console.error("Error fetching report or upgrade status:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const handleGenerateSummary = async () => {
    try {
      console.log("Fetching summary data for userId:", userId);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/useranswers/summary/${userId}`
      );
      console.log("Received summary data:", response.data);
      setSummary(response.data);
      setShowSummary(true);
    } catch (error) {
      console.error("Error generating summary:", error);
      console.log("Error response:", error.response);
    }
  };

  const handleDownloadPdf = async () => {
    try {
      console.log("Initiating PDF download...");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/useranswers/summary/${userId}/pdf`,
        { responseType: "blob" }
      );
      console.log("PDF download response received:", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "career_summary.pdf");
      document.body.appendChild(link);
      link.click();
      console.log("PDF download completed");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
      }
      alert("Failed to download PDF. Please try again.");
    }
  };

  const handleSendEmail = async () => {
    try {
      console.log("Initiating email send...");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/useranswers/summary/${userId}/email`
      );
      console.log("Email send response:", response);
      alert("Summary sent to your email!");
    } catch (error) {
      console.error("Error sending email:", error);
      if (error.response) {
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
      }
      alert("Failed to send email. Please try again.");
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const paymentStatus = query.get("payment");
    const sessionId = query.get("session_id");

    if (paymentStatus === "success" && sessionId) {
      console.log("Payment success detected, updating upgrade status");
      // First verify the payment
      axios
        .get(`${process.env.REACT_APP_API_URL}/verify-payment/${sessionId}`)
        .then((verifyResponse) => {
          if (verifyResponse.data.success && verifyResponse.data.hasUpgraded) {
            setHasUpgraded(true);
            // Then update the local upgrade status
            return axios.post(
              `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade`
            );
          }
        })
        .then((response) => {
          if (response?.data.success) {
            console.log("Upgrade status successfully updated");
            // Fetch fresh data after successful update
            return fetchReportAndUpgradeStatus();
          }
        })
        .then(() => {
          setRedirectToCard("Work Environment Satisfaction");
          setShowConfetti(true);
          setTimeout(() => {
            setShowConfetti(false);
          }, 5000);
        })
        .catch((error) => {
          console.error(
            "Error in payment verification/upgrade process:",
            error
          );
        });
    }
  }, [location.search, userId, fetchReportAndUpgradeStatus]);

  useEffect(() => {
    if (redirectToCard && report) {
      const cardData = getCardData(report);
      const cardIndex = cardData.findIndex(
        (card) => card.title === redirectToCard
      );
      if (cardIndex !== -1) {
        setCurrentCard(cardIndex);
        setRedirectToCard(null);
      }
    }
  }, [redirectToCard, report]);

  useEffect(() => {
    if (hasPaid) {
      navigate(`/report/${userId}`);
      console.log(hasPaid);
    }
  }, [hasPaid, navigate, userId]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/useranswers/report/${userId}`)
      .then((response) => {
        console.log("Report Data:", response.data);
        console.log(hasPaid); // Debugging log
        const reportData = response.data;
        if (!reportData.topNegativeWorkSatisfiers) {
          reportData.topNegativeWorkSatisfiers = []; // Set a default value if undefined
        }
        setReport(reportData);
      })
      .catch((error) =>
        console.error("There was an error fetching the report!", error)
      );
  }, [userId]);

  useEffect(() => {
    if (report) {
      const cardData = getCardData(report);
      preloadNextImage(currentCard, cardData).catch((error) => {
        console.error("Error preloading next image:", error);
      });
    }
  }, [currentCard, report]);

  useEffect(() => {
    if (report) {
      const fac = new FastAverageColor();
      const img = new Image();
      img.src = getCardData(report)[currentCard].media.src;
      img.onload = () => {
        fac
          .getColorAsync(img)
          .then((color) => {
            document.body.style.backgroundColor = color.hex;
          })
          .catch((e) => {
            console.log(e);
            document.body.style.backgroundColor = "";
          });
      };

      return () => {
        document.body.style.backgroundColor = "";
      };
    }
  }, [currentCard, report]);

  useEffect(() => {
    setMediaLoading(true);
  }, [currentCard]);

  useEffect(() => {
    fetchReportAndUpgradeStatus();
  }, [fetchReportAndUpgradeStatus]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const summaryParam = query.get("showSummary");

    if (summaryParam === "true") {
      handleGenerateSummary();
    }
  }, [location.search]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!report) {
    console.log("Report is null, showing loading message");
    return <div>Loading...</div>;
  }
  console.log("Report State:", report);

  const cardData = report ? getCardData(report) : [];
  const jobSecurityIndex = cardData.findIndex(
    (card) => card.title === "Overall Job Happiness Score"
  );

  const nextCard = () => {
    if (currentCard < cardData.length - 1) {
      setCurrentCard((prevCard) => prevCard + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const prevCard = () => {
    if (currentCard > 0) {
      setCurrentCard((prevCard) => prevCard - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const separateActionSteps = (content) => {
    const nextStepsIndex = content.indexOf("Next Steps:");
    if (nextStepsIndex === -1) {
      return content;
    }

    const beforeNextSteps = content.slice(0, nextStepsIndex).trim();
    const stepsPart = content.slice(nextStepsIndex + 12);
    const steps = stepsPart
      .split(/\.|\?/)
      .filter(Boolean)
      .map((step) => `<li>${step.trim()}</li>`)
      .join("");

    return `${beforeNextSteps}<strong><br/><br/>Next Steps:</strong><ul>${steps.trim()}</ul>`;
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAskQuestion = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ask`,
        { question }
      );
      setResponse(response.data.answer);
    } catch (error) {
      console.error("There was an error asking the question!", error);
    }
  };

  const handlePaymentSuccess = () => {
    setHasPaid(true); // Update the payment status to true
  };

  const handleUpgradeClick = () => {
    navigate(`/payment?userId=${userId}`); // Redirect to the payment page
  };

  const handleMediaLoad = () => {
    setMediaLoading(false);
  };

  return (
    <>
      <AppBar
        position="static"
        className="menu-bar"
        elevation={0}
        sx={{ marginBottom: 1 }}
      >
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="../images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate(`/dashboard/${userId}`)}
            sx={{
              color: "#000000",
              border: "1px solid #000000",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            View Dashboard
          </Button>
        </Toolbar>
      </AppBar>
      <div className="report-container">
        {showConfetti && <ReactConfetti />}
        {showSummary ? (
          <SummaryPage
            summary={summary}
            onDownloadPdf={handleDownloadPdf}
            onSendEmail={handleSendEmail}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            minHeight="100vh"
            position="relative"
          >
            {!isChatOpen && (
              <>
                <Fab
                  onClick={() => {
                    prevCard();
                  }}
                  disabled={currentCard === 0}
                  variant="contained"
                  size="small"
                  color="primary"
                  className="fab"
                  style={{
                    position: "fixed",
                    top: "40%",
                    left: "10%",
                    transform: "translateY(-50%)",
                    opacity: "0.5",
                  }}
                >
                  &lt;
                </Fab>
                <Fab
                  onClick={() => {
                    nextCard();
                  }}
                  disabled={currentCard === cardData.length - 1}
                  variant="contained"
                  size="small"
                  color="primary"
                  className="fab-right"
                  style={{
                    position: "fixed",
                    top: "40%",
                    right: "10%",
                    transform: "translateY(-50%)",
                    opacity: "0.5",
                  }}
                >
                  &gt;
                </Fab>
              </>
            )}
            {currentCard > jobSecurityIndex &&
            !hasUpgraded &&
            !verifyingPayment ? (
              <UpgradeCard
                userId={userId}
                onUpgradeSuccess={() => {
                  console.log("Upgrade success callback triggered");
                  fetchUpgradeStatus();
                }}
              />
            ) : (
              <Card style={{ maxWidth: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      position="relative"
                      width="100%"
                      height="100%"
                      minHeight="200px"
                    >
                      {mediaLoading && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )}
                      <CardMedia
                        component={
                          isVideo(cardData[currentCard].media) ? "video" : "img"
                        }
                        src={cardData[currentCard].media.src}
                        poster={
                          isVideo(cardData[currentCard].media)
                            ? cardData[currentCard].media.poster
                            : undefined
                        }
                        controls={isVideo(cardData[currentCard].media)}
                        alt={cardData[currentCard].title}
                        onLoadedData={
                          isVideo(cardData[currentCard].media)
                            ? handleMediaLoad
                            : undefined
                        }
                        onLoad={
                          !isVideo(cardData[currentCard].media)
                            ? handleMediaLoad
                            : undefined
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: mediaLoading ? "none" : "block",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h4"
                        align="left"
                        component="div"
                        mb={3}
                      >
                        {cardData[currentCard].title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="text.secondary"
                        mb={2}
                      >
                        {cardData[currentCard].titleDescription}
                      </Typography>
                      {cardData[currentCard].showStars && (
                        <StarRating score={cardData[currentCard].score} />
                      )}
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="text.primary"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            separateActionSteps(cardData[currentCard].text)
                          ),
                        }}
                      />
                      {cardData[currentCard].title === "Thank You!" && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate(`/dashboard/${userId}`)}
                          style={{ marginTop: "20px" }}
                        >
                          View Dashboard
                        </Button>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default Report;
