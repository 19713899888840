import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/LandingPage";
import QuestionList from "./components/QuestionList";
import Report from "./components/Report";
import UserInfoForm from "./components/UserInfoForm";
import ErrorBoundary from "./components/ErrorBoundary";
import "./components/Chat.css";
import LoginPage from "./components/LoginPage";
import CareerDashboard from "./components/CareerDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import ResumeTemplates from "./components/dashboard/ResumeTemplates";
import CheatSheets from "./components/dashboard/CheatSheets";
import MidCareerSwitching from "./components/dashboard/MidCareerSwitching";
import LandYourDreamJob from "./components/dashboard/LandYourDreamJob";
import SpecialCoaching from "./components/dashboard/SpecialCoaching";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create theme with Inter font
const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    button: {
      textTransform: "none", // This prevents uppercase text in buttons
    },
  },
});

const App = () => {
  const handleSaveUserInfo = (userInfo) => {
    // Save user info to state or send to server
    console.log(userInfo);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route
            path="/questions"
            element={
              <ErrorBoundary>
                <QuestionList />
              </ErrorBoundary>
            }
          />
          <Route
            path="/userinfo"
            element={<UserInfoForm onSaveUserInfo={handleSaveUserInfo} />}
          />
          <Route path="/report/:userId" element={<Report />} />
          {/* <Route path="/payment/:userId" element={<PaymentPage />} /> */}
          <Route
            path="/report/:userId"
            element={
              <ErrorBoundary>
                <Report />
              </ErrorBoundary>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard/:userId"
            element={
              <ProtectedRoute>
                <CareerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/resume-templates/:userId"
            element={
              <ProtectedRoute>
                <ResumeTemplates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cheat-sheets/:userId"
            element={
              <ProtectedRoute>
                <CheatSheets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mid-career-switching/:userId"
            element={
              <ProtectedRoute>
                <MidCareerSwitching />
              </ProtectedRoute>
            }
          />
          <Route
            path="/land-your-dream-job/:userId"
            element={
              <ProtectedRoute>
                <LandYourDreamJob />
              </ProtectedRoute>
            }
          />
          <Route
            path="/special-coaching/:userId"
            element={
              <ProtectedRoute>
                <SpecialCoaching />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
