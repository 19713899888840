import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import "./LandingPage.css";
import CallMadeIcon from "@mui/icons-material/CallMade";
import MenuIcon from "@mui/icons-material/Menu";

localStorage.setItem("answersSubmitted", "false");

const lightColors = [
  "#FFFDD0", // Cream
  "#E6F3FF", // Light Sky Blue
  "#F0FFF0", // Honeydew
  "#FFF5E6", // Linen
  "#F0FFFF", // Azure
  "#FAFAD2", // Light Goldenrod Yellow
  "#E0FFFF", // Light Cyan
  "#F5F5DC", // Beige
  "#FFF0F5", // Lavender Blush
  "#F0F8FF", // Alice Blue
  "#F8F8FF", // Ghost White
  "#FFFAF0", // Floral White
  "#F5FFFA", // Mint Cream
  "#FFEFD5", // Papaya Whip
  "#E6E6FA", // Lavender
  "#FFF5EE", // Seashell
  "#F0E68C", // Khaki
  "#FFFFE0", // Light Yellow
  "#E0EEED", // Powder Blue
  "#FDF5E6", // Old Lace
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const fetchedQuestions = response.data.map((question) => ({
          ...question,
          backgroundColor:
            lightColors[Math.floor(Math.random() * lightColors.length)],
        }));
        setQuestions(fetchedQuestions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  const handleStart = () => {
    setIsLoading(true);
    navigate("/questions", { state: { questions } });
  };

  return (
    <>
      <AppBar position="static" className="menu-bar">
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="./images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          <Button
            className="menu-button"
            variant="contained"
            onClick={() => navigate("/login")}
            style={{ position: "absolute", right: "30px" }}
            endIcon={<CallMadeIcon />}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <ul class="background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Box
        className="landing-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <Card
          className="landing-card"
          style={{ borderRadius: "25px", padding: "35px" }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                src="/images/Landing Page/CAROL TITLE PIC.jpeg"
                alt="Career Path"
                style={{ borderRadius: "25px" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContent style={{ padding: "30px" }}>
                <Typography variant="h4" component="h1" gutterBottom>
                  Hi! I'm&nbsp;
                  <Box component="span" color="#176d93" fontWeight="600">
                    CoachCarol™
                  </Box>
                </Typography>

                <Typography variant="h5" component="h1" gutterBottom>
                  I will be asking you questions about your Career Health.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  I will analyze your current situation and offer valuable
                  recommendations.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  Feel free to chat with me regarding the results and to provide
                  guidance to improve your Career Agility.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  onClick={handleStart}
                  className="start-button"
                  disabled={isLoading}
                  endIcon={<CallMadeIcon />}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Let's Begin"}
                </Button>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/login")}
                  className="login-button"
                  style={{ marginLeft: "10px" }}
                >
                  Login
                </Button> */}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default LandingPage;
