import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  CardMedia,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate, useParams } from "react-router-dom";

const ResumeTemplates = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = [
    {
      name: "Audit, Accounting, Compliance, Risk Management, Finance, Banking",
      path: "/resumesamples/Audit, Accounting, Compliance, Risk Management, Finance, Banking Sample Resume.pdf",
      description: "Perfect for finance and compliance professionals",
      icon: DescriptionIcon,
      bgColor: "#F2F6FF",
      color: "#415ED4",
    },
    {
      name: "Business Support Manager",
      path: "/resumesamples/Business Support Manager Sample.pdf",
      description: "Perfect for business development and innovation",
      icon: DescriptionIcon,
      bgColor: "#E1F5F6",
      color: "#439E90",
    },
    {
      name: "Chief Marketing Officer, Marketing Director, Brand Management, FMCG",
      path: "/resumesamples/Chief Marketing Officer, Marketing Director,    Brand Management, FMCG Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F9F3D8",
      color: "#C38D3B",
    },
    {
      name: "CHRO, HR VP, HR Business Partner, Change Management, Transformation, Digital HR",
      path: "/resumesamples/CHRO, HR VP, HR Business Partner, Change Management, Transformation, Digital HR Resume Sample.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#FFF4F1",
      color: "#FF7954",
    },
    {
      name: "Corporate Communications , Marketing Communications, Crisis Management",
      path: "/resumesamples/Corporate Communications , Marketing Communications, Crisis Management, Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F7FFE4",
      color: "#99BF3D",
    },
    {
      name: "Finance Analyst",
      path: "/resumesamples/Finance Analyst Sample.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F9E8FB",
      color: "#A03AAC",
    },
    {
      name: "General Manager, Business Development, Product Development, Innovation, Consumer Electronics",
      path: "/resumesamples/General Manager, Business Development, Product Development, Innovation, Consumer Electronics Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#FBFBE8",
      color: "#9C9E51",
    },
    {
      name: "Global Payments, Financial Services, Product Strategy and Development, Technology Solutions",
      path: "/resumesamples/Global Payments, Financial Services, Product Strategy and Development, Technology Solutions Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#E5F9FE",
      color: "#36A5BE",
    },
    {
      name: "IT, Technology, Cloud, AI, Cybersecurity Sales Leader",
      path: "/resumesamples/IT, Technology, Cloud, AI, Cybersecurity Sales Leader Resume Sample.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#FFF1F7",
      color: "#BE366F",
    },
    {
      name: "Junior Client Services Banking",
      path: "/resumesamples/Junior Client Services Banking Sample.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F2F6FF",
      color: "#415ED4",
    },
    {
      name: "Office Manager, Admin Manager, Personal Assistant",
      path: "/resumesamples/Office Manager, Admin Manager, Personal Assistant Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#E1F5F6",
      color: "#439E90",
    },
    {
      name: "Pharmaceutical Sales, Business Development",
      path: "/resumesamples/Pharmaceutical Sales, Business Development Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F9F3D8",
      color: "#C38D3B",
    },
    {
      name: "Supply Chain, Operations Manager",
      path: "/resumesamples/Supply Chain, Operations Manager Sample Resume.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#FFF4F1",
      color: "#FF7954",
    },
    {
      name: "Technical IT",
      path: "/resumesamples/Technical IT Sample.pdf",
      description: "Perfect for finance analysis and reporting",
      icon: DescriptionIcon,
      bgColor: "#F7FFE4",
      color: "#99BF3D",
    },
  ];

  const handleDownload = (template) => {
    setSelectedTemplate(template);
    setOpenDialog(true);
  };

  const confirmDownload = () => {
    const link = document.createElement("a");
    link.href = selectedTemplate.path;
    link.download = selectedTemplate.name + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpenDialog(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppBar
        position="static"
        className="menu-bar"
        elevation={0}
        sx={{ borderRadius: 20, marginBottom: 1 }}
      >
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="../images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate(`/dashboard/${userId}`)}
            sx={{
              color: "#000000",
              border: "1px solid #000000",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            View Dashboard
          </Button>
        </Toolbar>
      </AppBar>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#8DDAF0",
        }}
      >
        <CardContent sx={{ flexGrow: 1, bgcolor: "#FFFFFF" }}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography gutterBottom variant="h5" component="div">
              Resume Samples
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" paragraph>
            Access professional resume samples designed for various career
            levels and industries.
          </Typography>
          <Box
            sx={{
              backgroundColor: "#8DDAF0",
              margin: "0 -24px",
              padding: "0 24px",
            }}
          >
            <Grid container spacing={2} sx={{ backgroundColor: "#8DDAF0" }}>
              {templates.map((template, index) => {
                const Icon = template.icon;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    sx={{ mb: 3 }}
                  >
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 5,
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <CardMedia
                        sx={{
                          bgcolor: template.bgColor,
                          pt: 2,
                          pb: 2,
                          pl: 2,
                          m: 3,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          position: "relative",
                          borderRadius: 5,
                          minHeight: "150px",
                        }}
                      >
                        <Box
                          sx={{
                            width: 56,
                            height: 56,
                            borderRadius: "50%",
                            bgcolor: template.color,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mb: 1,
                          }}
                        >
                          <Icon sx={{ fontSize: 32, color: "white" }} />
                        </Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ color: template.color }}
                        >
                          {template.name}
                        </Typography>
                      </CardMedia>
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          pt: 2,
                          pl: 3,
                          pb: "60px !important",
                          position: "relative",
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 2 }}
                        >
                          {/* {template.description} */}
                        </Typography>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 16,
                            left: 16,
                            right: 16,
                            display: "flex",
                            justifyContent: "left",
                          }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            onClick={() => handleDownload(template)}
                            sx={{
                              bgcolor: template.color,
                              borderRadius: 5,
                              "&:hover": {
                                bgcolor: `${template.color}CC`,
                              },
                            }}
                          >
                            Download
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Download Resume Template</DialogTitle>
        <DialogContent>
          <Typography paragraph>{selectedTemplate?.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedTemplate?.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDownload} variant="contained">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResumeTemplates;
