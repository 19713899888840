import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Link,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useNavigate, useParams } from "react-router-dom";
const SpecialCoaching = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const coachingLink =
    "https://calendly.com/adrian-careeragility/coachcarol-careerclinic";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppBar
        position="static"
        className="menu-bar"
        elevation={0}
        sx={{ borderRadius: 20, marginBottom: 1 }}
      >
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="../images/Logo/cai logo.jpg"
              alt="Company Logo"
              className="logo"
            />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate(`/dashboard/${userId}`)}
            sx={{
              color: "#000000",
              border: "1px solid #000000",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            View Dashboard
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ px: { xs: 2, sm: "10%" } }}>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#F7FFE4",
            my: 2,
          }}
        >
          <CardContent sx={{ flexGrow: 1, bgcolor: "#FFFFFF" }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography gutterBottom variant="h5" component="div">
                Let us Help you with Your Career
              </Typography>
            </Box>

            <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
              Sometimes, All You Need Is Some Good Old-fashioned Advice.
            </Typography>

            <Box
              sx={{
                backgroundColor: "#F7FFE4",
                borderRadius: 5,
                p: 3,
                mb: 3,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 3,
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <Box
                component="img"
                src="/images/SpecialCoaching/adrian and yen spotify.jpg"
                alt="Career Coach"
                sx={{
                  width: { xs: "100%", sm: 200 },
                  maxWidth: { xs: 300, sm: 200 },
                  height: "auto",
                  aspectRatio: "1",
                  objectFit: "cover",
                  borderRadius: 3,
                }}
              />
              <Box>
                <Typography variant="body1" paragraph>
                  With decades of experience coaching top Business Leaders,
                  Adrian or Yen will be able to offer some friendly and
                  practical advice to your pressing career questions.
                </Typography>
                <Typography variant="body1" paragraph>
                  Our standard rate is US$265.00/hr, but as a Premium Member,
                  you can enjoy your first session with us at only US$79.00/hr.
                  Hurry, get some Career Clarity now!
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              my={3}
            >
              <Box
                component="img"
                src="/images/SpecialCoaching/coachcarol special coaching QR code.png"
                alt="QR Code for Coaching Session"
                sx={{
                  width: 150,
                  height: 150,
                  mb: 2,
                  border: "1px solid #E0E0E0",
                  borderRadius: 2,
                }}
              />
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Scan QR code or click below to schedule
              </Typography>
            </Box>

            <Button
              variant="contained"
              fullWidth
              startIcon={<SupervisorAccountIcon />}
              href={coachingLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                bgcolor: "#99BF3D", // Matching the theme color from CheatSheets
                borderRadius: 5,
                "&:hover": {
                  bgcolor: "#99BF3D",
                },
              }}
            >
              Schedule Your Session Now
            </Button>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default SpecialCoaching;
